import React, { useMemo } from 'react';

import { safeJsonParse } from 'wix-rich-content-common';
import type { IAboutGroupChangedActivity } from 'api/feed/types';

import { CardContent } from 'wui/CardContent';
import { RichContentViewer } from 'common/components/RichContent/Viewer';

import { getAriaId } from '../a11y';
import { IActivityContentProps } from './types';

export function DescriptionChanged(props: IActivityContentProps) {
  const { item } = props;

  const feedItemId = item.feedItemId as string;
  const data = item?.activity?.data as IAboutGroupChangedActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useMemo(() => safeJsonParse(data.content), [data.content]);

  return (
    <CardContent id={getAriaId(feedItemId, 'content')}>
      <RichContentViewer
        usage="FeedItem"
        content={content}
        groupId={groupId}
        contentId={item.feedItemId}
      />
    </CardContent>
  );
}

DescriptionChanged.displayName = 'DescriptionChanged';
