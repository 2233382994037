import { curryRight, without } from 'lodash';
import { createSelector } from 'reselect';

import { selectReactions } from 'store/selectors';

import type { PrimaryReactionsVariant } from './types';

export const selectReactionCodes = curryRight(
  createSelector(
    [
      selectReactions,
      (_, __, variant: PrimaryReactionsVariant) => {
        switch (variant) {
          case 'only_heart':
            return ['❤️'];

          case 'heart_and_thumbsup':
            return ['👍', '❤️'];

          default:
            return ['👍'];
        }
      },
    ],
    (summary, primary) => {
      const reactions = summary?.reactions.map((reaction) => reaction.code);

      return [primary, without(reactions, ...primary)];
    },
  ),
  3,
);
