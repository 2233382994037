import { useMemo } from 'react';
import type { RicosTheme } from '@wix/ricos-common/dist/types';

interface IProps {
  /**
   * parent class name (where apply styles to)
   */
  parentClass: string;
}

export function useRicosTheme(props: IProps) {
  const { parentClass } = props;

  return useMemo<RicosTheme>(
    () => ({
      parentClass,
      settingsStyles: { modals: {} }, // has to be present to enable css variables for ricos modals 🤷‍♂️
    }),
    [parentClass],
  );
}
